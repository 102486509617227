.property-block {
     margin: 80px 0;
     .property-list-title {
          & > div , & > h1 {
               float: left;
          }
          & > div {
               width: 22%;
          }
          & > h1 {
               @include font-size(32);
               font-weight: 700;
               width: 56%;
               text-align: center;
          }
          .properties-counter {
               p {
                    @include font-size(15);
                    line-height: 44px;
                    font-weight: 300;
                    margin-bottom: 0;
               }
          }
          .filter-box {
               height: 44px;
               .sort-block {
                    position: relative;
                    float: right;
                    .sort-label {
                         position: absolute;
                         display: inline-block;
                         left: -3.5em;
                         top: .75em;
                    }
               }
               .sort-label, .SumoSelect > .CaptionCont > span {
                    @include font-size(15);
               }

               .SumoSelect {
                    width: 145px;
                    margin: 7px 0;
                    .SumoUnder {
                         margin-bottom: 0;
                    }
                    & > .CaptionCont {
                         border: 1px solid #e2e2e2;
                         border-radius: 3px;
                         padding: 2px 8px;
                         & > span {
                              font-weight: 300;
                         }
                         & > label {
                              & > i {
                                   background-image: url('../images/svg/angle_down.svg');
                              }
                         }
                    }
               }
          }
     }
}

.listing-properties-block {
     margin-top: 43px;
     .listing-properties-elements {
          & > ul {
               margin: 0;
               & > li {
                    position: relative;
                    width: 370px;
                    list-style: none;
                    border-radius: 4px;
                    overflow: hidden;
                    margin:  0 30px 40px 0;
                    float: left;
                    box-shadow: 0px 3px 3px 0px rgba(46, 46, 46, 0.15);
                    &:nth-child(3n) {
                         margin-right: 0;
                    }
                    .image-block {
                         position: relative;
                         width: 100%;
                         height: 250px;
                         overflow: hidden;
                         img {
                              width: 100%;
                              height: inherit;
                              object-fit: cover;
                         }
                         .favorite-tag {
                              position: absolute;
                              top: .25em;
                              right: .5em;
                              width: 34px;
                              height: 34px;
                              line-height: 34px;
                              text-align: center;
                              @include font-size(18);
                              color: #dfe0e1;
                              background-color: rgba(0,0,0,.5);
                              border-radius: 18px;
                              cursor: pointer;
                              &:hover {
                                   background-color: rgba(129, 30, 30,.9);
                              }
                              img {
                                   display: block;
                                   width: 20px;
                                   height: auto;
                                   margin: calc((34px - 16px ) / 2) calc((34px - 20px ) / 2);
                              }
                         }
                         .options-block {
                              position: absolute;
                              bottom: 0;
                              left: 0;
                              width: 100%;
                              padding: .5em 20px;
                              background-color: rgba(0,0,0,.45);
                              z-index: 9990;
                              & > ul {
                                   margin: 0;
                                   height: 29px; // Safari fix
                                   & > li {
                                        vertical-align: middle;
                                        display: inline;
                                        color: #fff;
                                        padding: 0 .25em 0 0;
                                        margin-right: .25em;
                                        font-weight: 300;
                                        img {
                                             display: inline-block;
                                             width: 18px;
                                             height: auto;
                                             margin-bottom: 4px;
                                        }
                                        &.property-area {
                                             margin-right: 4px;
                                             padding: 0;
                                        }
                                        &.total-beds {
                                             img {
                                                  width: 29px;
                                                  height: 22px;
                                             }
                                        }
                                   }
                              }
                         }
                         .more-info {                              
                              position: absolute;
                              background: transparent;
                              top: 20%;
                              bottom: 0;
                              width: 100%;
                              z-index: 9999;
                              h4 {
                                   transition: all .5s ease-out;
                                   color: transparent;
                                   @include font-size(22);
                                   font-weight: 300;
                                   text-transform: uppercase;
                                   text-align: center;
                                   margin-top: 35%;
                              }
                              &.hover {
                                   transition: all .5s ease-out;
                                   top: 0;
                                   left: 0;
                                   background: -moz-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                                   background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                                   background: linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                   h4 {                    
                                        color: #fff;
                                        margin-top: 30%;
                                   }
                              }
                         }
                    }  
                    .title-block {
                         display: block;
                         background-color: #fff;
                         color: #4a4a4a;
                         padding: 20px;
                         strong, span {
                              font-weight: 300;
                         }
                         span {
                              display: block;
                         }
                    }
                    .extras-block {
                         background-color: #f0f0f0;
                         height: 48px;
                         padding: 10px 20px;
                         & > ul, & > div {
                              float: left;
                         }
                         ul {
                              width: 45%;
                              margin: 0;
                              li {
                                   list-style: none;
                                   float: left;
                                   margin-right: 1em;
                                   img {
                                        width: 23px;
                                        height: auto;
                                   }
                              }
                         }
                         .property-tag.price {
                             width: 55%;
                             font-weight: 300;
                             @include font-size(14);
                             text-align: right;
                             strong {
                                  font-weight: bold;
                                   @include font-size(18);
                             }
                         }
                    }
               }
          }
     }
}

@include breakpoint(small only) {
     .property-block {
          margin: 40px 0 20px;
          .property-list-title {
               display: flex;
               flex-direction: column;
               .properties-counter {
                    order: 2;
                    width: 100%;
                    p {
                         line-height: normal;
                         margin-bottom: 1em;
                         text-align: center;
                    }
               }
               & > h1 {
                    order: 1;
                    width: 100%;
                    margin: .5em 0;
               }
               .filter-box {
                    order: 3;
                    width: 100%;
                    .sort-block {
                         .sort-label {
                              left: -4.25em;
                              top: .55em;
                         }
                    }
                    .SumoSelect {
                         width: 120px;
                    }
               }
          }
     }
     .listing-properties-block {
          margin-top: 0;
          .listing-properties-elements {
               & > ul {
                    & > li {
                         width: 100%;
                         margin: 0 0 20px;
                         .extras-block {
                              .property-tag.price {
                                   @include font-size(14);
                                   strong {
                                        @include font-size(16);
                                   }
                              }
                         }
                    }
               }
          }
     }
     .pagination-block {
          margin: 16px 0 36px;
     }
}

@include breakpoint(medium only) {
     .property-block {
          margin: 40px 0 20px;
          .property-list-title {
               display: flex;
               flex-direction: column;
               .properties-counter {
                    order: 2;
                    width: 100%;
                    p {
                         line-height: normal;
                         margin-bottom: 1em;
                         text-align: center;
                    }
               }
               & > h1 {
                    order: 1;
                    width: 100%;
                    margin: .5em 0;
               }
               .filter-box {
                    order: 3;
                    width: 100%;
                    .sort-block {
                         .sort-label {
                              left: -4.5em;
                              top: .65em;
                         }
                    }
                    .SumoSelect {
                         width: 145px;
                    }
               }
          }
     }
     .listing-properties-block {
          margin-top: 0;
          .listing-properties-elements {
               & > ul {
                    & > li {
                         width: calc((100% - 20px) / 2);
                         margin: 0 20px 20px 0 !important;
                         &:nth-child(2n) {
                              margin-right: 0 !important;
                         }
                    }
               }
          }
     }
     .pagination-block {
          margin: 16px 0 36px;
     }
}