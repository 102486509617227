#ui-datepicker-div {
     z-index: 99999 !important;
     @include font-size(14);
     background-color: transparent;
     border-radius: 5px;
     background: -moz-linear-gradient(bottom, #eee 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
     background: -webkit-linear-gradient(bottom, #eee 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
     background: linear-gradient(to top, #eee 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
     .ui-datepicker-header {
          background: transparent url('../images/calendar_caret.png') top center no-repeat;
          padding-top: 17px;
          margin-top: 4px;
          background-color: transparent;
          position: relative;
          .ui-datepicker-prev,
          .ui-datepicker-next {
               &:after {
                    display: block;
                    position: absolute;
                    top: 26%;
                    font-family: $icon-font;
                    @include font-size(32);                   
                    color: #cfcdcd;
               }
               &:hover, &:active, &:focus {
                    &:after {
                         color: $second-color;
                    }
               }
               span {
                    position: absolute;
                    text-indent: 9999999px;
                    z-index: -9;
               }
          }
          .ui-datepicker-prev {
               &:after {                    
                    content: '\f104';
                    left: .25em;
               }
          }
          .ui-datepicker-next {
               &:after {                    
                    content: '\f105';
                    right: .25em;
               }
          }
          .ui-datepicker-title {
               border-radius: 5px 5px 0 0;
               background-color: #eee;
              @include font-size(18);
               text-align: center;
               padding: .75em 1.5em;
          }
         
     }
     table {
          thead {
               background-color: #838079;
               color: #fff;
               text-transform: uppercase;
               th {
                    padding: .625rem .625rem;
                    span {
                         font-weight: 300;
                    }
               }
          }
          tbody {
               tr {
                    td {
                         padding: .625rem .625rem;
                         text-align: center;
                         a {
                              color: $placeholder-color;              
                         }
                         &:hover,&:active, &:focus {
                              background-color: $first-color;
                              border-radius: 3px;
                              a {
                              color: #fff;  
                              }
                         
                         }
                         &.ui-state-disabled {
                              span {
                                   color: #d4d4d4;                        
                              }
                         }
                    }
               }
          }
     }
}

.datepicker-block-outer {
     position: relative;
     margin-bottom: 70px;
     a#prev-months,a#next-months {
          position: absolute;
          display: block;
          top: calc(50% - (60px / 2));
          @include font-size(30);
          color:$font-color;
          background-color: $slick-arrows;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 32px;
          &:hover, &:active {
               background-color: darken($second-color,10%);
               color: #fff;
          }
          i {
               line-height: 32px;
          }
     }
     a#prev-months {
          left: -3em;
     }
     a#next-months {
          right: -3em;
     }
     #datepicker-block {
          .ui-datepicker-inline {
               width: 100% !important;
               .ui-datepicker-group {
                    width: calc((100% - 39px) / 4);
                    min-height: 295px;
                    float: left;
                    margin-right: 13px;
                    border-radius: 4px;
                    box-shadow: 0px 2px 4px 0px rgba(159, 159, 159, 0.5);
                    &:nth-child(4) {
                         margin-right: 0;
                    }
                    .ui-datepicker-header {
                         background-color: #eeeeee;
                         text-align: center;
                         @include font-size(18);
                         font-weight: 300;
                         padding: 15px 20px;
                         border-radius: 4px 4px 0 0;
                         a.ui-datepicker-prev, a.ui-datepicker-next {
                              display: none;
                         }
                    }
                    table {
                         margin-bottom: .5em;
                         thead {
                              background-color: $first-color;
                              text-transform: uppercase;
                              color: #fff;
                              @include font-size(14);
							 
							 th{
							 	padding-bottom: 0.42rem !important;
							 }
                         }
                         tbody {
                              border: none;
                              tr {
                                   &:nth-child(even) {
                                        background-color: #fff;
                                   }
                                   td {
                                        padding: 0.25rem 0.625rem 0.25rem;
									    text-align: center;
                                        a, span {
                                             @include font-size(14);
                                        }
                                        a {
                                             &.ui-state-default {
                                                  color: $font-color;
                                             }
                                        }
                                        &.ui-state-disabled {
                                             color: #cccccc;
                                        }
                                       
                                   }
                              }
                         }
                    }
               }
          }
     }
}

@include breakpoint(small only) {
     .datepicker-block-outer {
          #datepicker-block {
               .ui-datepicker-inline {
                   .ui-datepicker-group {
                        width: 100%;
                        margin: 0 0 20px 0;
                   }
               }
          }
          a#prev-months, a#next-months {
               top: auto;
               bottom: -1.8em;
          }
          a#prev-months {
               left: 3.5em;
          }
          a#next-months {
               right: 3.5em;
          }
     }
}


@media screen and (min-width:768px) and (max-width:910px) {
     .datepicker-block-outer {
          #datepicker-block {
               .ui-datepicker-inline {
                   .ui-datepicker-group {
                         width: calc((100% - 120px) / 2);  
                         margin-right: 30px;
                         margin: 10px 30px;                  
                   }
               }
          }
          a#prev-months, a#next-months {
               top: auto;
               bottom: -2em;
          }
          a#prev-months {
               left: 30px;
          }
          a#next-months {
               right: 30px;
          }
     }
}

@media screen and (min-width:911px) and (max-width:1023px) {
     .datepicker-block-outer {
          #datepicker-block {
               .ui-datepicker-inline {
                   .ui-datepicker-group {
                         width: calc((100% - 30px) / 3);  
                         margin-right: 30px;
					     margin: 10px 10px 20px 0;
                         &:nth-child(3) {
                              margin-right: 0;
                         }                     
                   }
               }
          }
          a#prev-months, a#next-months {
               top: auto;
               bottom: -1.75em;
          }
          a#prev-months {
               left: 0em;
          }
          a#next-months {
               right: 0em;
          }
     }
}

@include breakpoint(large only) {
     .datepicker-block-outer {
          #datepicker-block {
               .ui-datepicker-inline {
                   .ui-datepicker-group {
                         width: calc((100% - 90px) / 3);  
                         margin-right: 30px;
                         margin: 10px 15px 20px;                  
                   }
               }
          }
          a#prev-months, a#next-months {
               top: auto;
               bottom: -2.2em;
          }
          a#prev-months {
               left: 15px;
          }
          a#next-months {
               right: 15px;
          }
     }
}

@media screen and (min-width:75em) and (max-width:85.625em) {
     .datepicker-block-outer {
          #datepicker-block {
               .ui-datepicker-inline {
                   .ui-datepicker-group {
                         width: calc((100% - 90px) / 3);  
                         margin-right: 30px;
                         margin: 10px 15px 20px;                  
                   }
               }
          }
          a#prev-months, a#next-months {
               top: auto;
               bottom: -2.2em;
          }
          a#prev-months {
               left: 15px;
          }
          a#next-months {
               right: 15px;
          }
     }
}