@mixin font-size($size) {
     font-size: $size + px;
     font-size: $size / 16 + em;
     font-size: $size / 16 + rem;
 }
 
 p.clearfix {
      clear: both;
      margin: 0;
      padding: 0;
      width: 0;
      height: 0;
 }