#footer {
     width: 100%;
     background-color: $footer-color;
     .footer-top-block {
          border-bottom: 1px solid #95928d;
          strong {
               display: block;
               @include font-size(24);
               font-weight: 500;
               margin: 1.75em 0;
               color: #fff; 
          }
          a.web-link {
               display: block;
               width: calc((100% - 80px) / 5);
               text-align: center;
               background-color: $web-link;
               color: $font-color;
               padding: 1.25em 0;
               margin: 0 20px 20px 0;
               border-radius: 3px;
               float: left;
               &:nth-child(5n+1) {
                    margin-right: 0;
               }
               &:hover {
                    background-color: $web-link-hover;
                    color: #fff;
               }
          }
     }
     .footer-bottom-block {
          height: 100px;
          p {
               line-height: 100px;
               display: inline;
               @include font-size(14);
               a {
                    color: $font-color;
               }
          }
          
          ul {
               margin: 0;
               float: right;
               li {
                    display: inline;
                    margin-left: 1em;
                    a {
                         line-height: 100px;
                         color: #fff;
                         &:hover {
                              color: darken(#fff, 20%);
                         }
                    }
               }
          }
     }
}

@include breakpoint(small only) {
     #footer {
          .footer-top-block {
               strong {
                    text-align: center;
               }
               a.web-link {
                    width: 220px;
                    display: block;
                    float: none;
                    margin: 0 calc(50% - (220px / 2)) 10px;
               }
               .links-row {
                    margin: 0;
               }
          }
          .footer-bottom-block {
               .row {
                    .large-12 {
                         display: flex;
                         flex-direction: column;
                    }
               }
               p {
                    @include font-size(12);
                    text-align: center;
                    line-height: normal;
                    display: block;
                    order: 2;
                    margin: 0;
               }
               ul {
                    order: 1;
                    margin: 1em 0;
                    li {
                         margin-left: .5em;
                         a {
                              @include font-size(14);
                              line-height: normal;
                         }
                    }
               }
          }
     }
}

@include breakpoint(medium only) {
     #footer {
          .footer-top-block {
               strong {
                    text-align: center;
               }             
               a.web-link {
                    width: calc((100% - 40px)/3);
                    margin: 0 20px 20px 0;
                    &:nth-child(3n+1) {
                         margin-right: 0;
                    }
                    &:nth-child(5n+1) {
                         margin-right: 20px;
                    }
                    &:nth-last-child(2) {
                         margin: 0 calc((100% / 3) + 6px) 20px;
                    }
               }
               
          }
          .footer-bottom-block {
               height: 200px;
               .row {
                    .large-12 {
                         display: flex;
                         flex-direction: column;
                    }
               }
               p {
                    @include font-size(14);
                    text-align: center;
                    line-height: normal;
                    display: block;
                    order: 2;
                    margin: 0;
               }
               ul {
                    order: 1;
                    width: 75%;
                    margin: 1.5em 12.5%;
                    li {                        
                         text-align: center;
                         margin: 0 1.25em;
                         a {
                              @include font-size(16);
                              line-height: normal;
                         }
                    }
               }
          }
     }
}
