
// Fonts
$font-family: 'Roboto', sans-serif;
$icon-font: 'fontawesome';

// Colors
$first-color: #e7a315;
$second-color: #f16803;
$third-color: #c20b18;
$font-color: #4a4a4a;
$placeholder-color: #454a53;
$situation-tag: #397fce;
$property-tag: #df9c11;
$slick-arrows: #e3e3e3;
$footer-color: #88857f;
$web-link: #e2e2e2;
$web-link-hover: #3d84d4;
$detail-links: #fd6e04;
$options-block-bg: #faf9f7;
$detail-font-color: #828385;
$reservations-bg: #faf9f7;
$facebook-bg: #4a6ea8;
$total-cost-bg: #faf3e3;
$sticky-detail-top: #808080;
$description-text: #545658;
$input-border: #e6e4e4;
$placeholder-color: #505050;
$logo: #545454;
$favorites-block-bg: rgb(226, 226, 226);

// General styles 
body {
     overflow-x: hidden;
     font-family: $font-family;
     font-size: 1em;
     color: $font-color;
     -webkit-font-smoothing: antialised;
     -moz-osx-font-smoothing: auto;
     font-smooth: auto;
}

h1,h2,h3,h4,h5,h6 {
     font-family: $font-family;
     font-weight: 600;
}

h1 {
     @include font-size(32);
}

h2 {
     @include font-size(18);
}