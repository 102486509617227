.pagination-block {
     margin: 36px 0 76px;
     .pagination {
          li {
               border: 1px solid $slick-arrows;
               border-radius: 3px;
               padding: 1px 2px;
               vertical-align: middle;
               &.ellipsis {
                    border: none;
                    vertical-align: top;
                    margin-top: 3px;
                    &:hover, &:active, &:focus {
                         background: transparent;
                    }
                    &:after {
                        color: lighten($font-color, 40%);
                        position: relative;
                        margin-top: -12px;
                    }
               }
               &:hover,&:active,&:focus {
                    background: $first-color;
                    a {
                         background: transparent;
                         color: #fff;
                    }
               }
               &.current {
                    background: $first-color;
                    border: 1px solid darken($first-color,5%);
                    a {
                         color: #fff;
                    }
                    &:hover,&:active,&:focus {
                         a {
                              background: transparent;
                              color: #fff;
                         }
                    }
               }
               &.pagination-next, &.pagination-previous {
                    background: $slick-arrows;
                    padding: .35em .45em .4em;
                    border-radius: 1.75em;
                    &.pagination-next {
                         img {
                              transform: rotate(180deg);
                         }
                    }
                    &:hover, &:active, &:focus {
                         border-color: $first-color;
                         background: $first-color;
                         cursor: pointer;
                    }
                    &.disabled {
                         cursor: not-allowed;
                         &:hover {
                              background-color: $slick-arrows;
                              border-color: $slick-arrows;
                         }
                    }
                    img {
                         width: 26px;
                         height: auto;
                    }     
                    a {
                         padding: 0;
                         &:before, &:after {
                              display: none;
                         }                  
                    }
                    &:before,&:after {
                         display: none;
                    }
               }
               &.pagination-next {
                    margin-left: .75em;                    
               }
               &.pagination-previous {
                    margin-right: .75em;
               }
               a {
                    color: lighten($font-color, 40%);
               }
          }
     }
}