#viviendas {
     #header {
          border-bottom: 1px solid $web-link;
     }
}

.detail-page-content {
     .detail-content-menu {
          border-bottom: 1px solid $web-link;
          background-color: #fff;
          position: static;
          margin: 0 0 30px;
          height: 60px;
          li {
               display: inline-block;
               margin-right: 2em;
               line-height: 60px;
			   
               img {
                    width: 16px;
                    height: auto;
                    margin-bottom: 3px;
               }
               a {
                    color: $sticky-detail-top;
				    @include font-size(15);
                    font-weight: 300;
                    text-align: center;
                    line-height: inherit;
               }
               &:nth-child(1) {
                    a {
                         color: $detail-links;
                         font-weight: 700;
                    }
               }
               &:nth-child(7) {
                    img {
                         width: 20px;
                         height: auto;
                         margin-bottom: -2px;
                    }
                    a {
                         color: $detail-links;
                         text-decoration: underline;
                         font-weight: 600;
                    }
               }
               &.active {
                    line-height: 51px;
                    border-bottom: 3px solid $first-color;
                    a {
                         color: $font-color;
                    }
               }
          }
         
     }
     .property-header {
          position: relative;
          margin-bottom: 32px;
          h1 {
               @include font-size(32);
               color: #525150;
               font-weight: 500;
               margin-bottom: 0;
          }
          .facebook-block {
               position: absolute;
               right: 0;
               bottom: -16px;
               a {
                    color: $placeholder-color;
                    i {
                         @include font-size(20);
                         margin-left: 8px;
                         padding: 6px 10px;
                         border-radius: 5px;
                         color: #fff;
                         background-color: $facebook-bg;
                    }
                    &:hover {
                         i {
                              background-color: #3B5998;
                         }
                    }
               }
          }
     }
     .photos-block {
          overflow: auto;
          margin: 30px 0 40px;
          .detail-page-outer {
               float: left;
               width: 870px;
               height: 530px;
               overflow: hidden;
               position: relative;
               margin-right: 8px;
               &:after {
                    content: '';
                    display: table;
                    clear: both;
               }
               img {
                    width: 870px;
                    height: 530px;
                    object-fit: cover;
               }
               .magnifier-icon {
                    position: absolute;
                    background-color: rgba($first-color,.75);
                    padding: .5em .6em;
                    top: 20px;
                    left: 20px;
                    z-index: 99;
                    border-radius: 36px;
                    &:hover {
                         cursor: pointer;
                    }
                    img {
                         width: 26px;
                         height: 26px;
                    }
               }
               .icons-block {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 99;
                    a > div {
                         float: left;
                         border-radius: 38px;
                         i {
                              @include font-size(20);
                              color: #fff;
                              text-align: center;
                         }
                         &.favorite-block {
                              background-color: rgba(0,0,0,0.5);
                              padding: .75em .75em .55em;
                              &:hover {
                                   background-color: rgba(129,30,30,0.9)
                              }
                         }
                    }
               }
               .options-block-outer {
                    position: absolute;
                    width: 100%;
                    height: 72px;
                    padding: 0;
                    background-color: rgba(0,0,0,.75);
                    left: 0;
                    bottom: 0;
                    .options-block {
                         float: left;
                         padding: 20px;
                         & > ul {
                              margin: 0;
                              & > li {
                                   color: #fff;
                                   display: inline;
                                   @include font-size(18);
                                   font-weight: 300;
                                   line-height: 32px;
                                   margin-right: 32px;
                                   img {
                                        display: inline-block;
                                        width: 26px;
                                        height: auto;
                                        margin-right: 11px;
                                   }
                                   &.total-people {
                                        img {
                                             margin-right: 8px;
                                        }                                      
                                   }
                                   &.total-beds {
                                        img {
                                             width: 35px;
                                        }
                                   }
                              }
                         }
                    }
                    .extras-block {
                         float: right;
                         padding: 20px;
                         & > ul {
                              margin: 0;
                              text-align: right;
                              & > li {
                                   color: #fff;
                                   display: inline;
                                   margin-right: 20px;
                                   img {
                                        display: inline-block;
                                        width: 30px;
                                        height: auto;
                                   }
                                   &:last-child {
                                        margin-right: 0;
                                   }
                              }
                         }
                    }
               }
              
          }
          .img-lateral-block {
               width: 292px;
               float: left;
               .img-full {
                    float: left;
                    width: 100%;
                    height: 220px;
                    margin-bottom: 8px;
                    img {
                         height: 220px;
                    }
               }
               .img-half {
                    width: calc((100% - 8px) / 2);
                    height: 107px;
                    margin: 0 8px 8px 0;
                    float: left;
                    &:nth-child(2n+1) {
                         margin-right: 0;
                    }
                    img {
                         height: 107px;
                    }
               }
               .img-full, .img-half {
                    overflow: hidden;
                    img {
                         width: 100%;
                         max-width: none;
                         object-fit: cover;
                    }                   
               }
               .img-footer-block {
                    float: left;
                    width: 100%;
                    height: 72px;
                    background-color: $detail-links;
                    button {
                         background-color: transparent;
                         @include font-size(18);
                         color: #fff;
                         width: 100%;
                         height: 100%;
                         text-align: center;
                         cursor: pointer;
                         img {
                              width: 24px;
                              height: 24px;
                              margin-right: .25em;
                         }
                    }
                    .vr-block {
                         width: 100%;
                         img {
                              width: 40px;
                              height: auto;
                         }
                    }
               }
          }
     }
     .detail-description-block-outer {
          display: block;
          width: 800px;
          float: left;
          h3 {
               @include font-size(22);
               font-weight: 500;
               margin-bottom: 1.0em;
          }
          p {
               color: $description-text;
               font-weight: 300;
               margin-bottom: 1.65em;
          }
          & > div {
               margin-bottom: 40px;
          }
          .read-more {
               color: $first-color;
               width: auto;
               &:hover { 
                    cursor: pointer;
                    color: darken($second-color, 20%);
               }
          }
     }
     .description-options-block {
          width: 290px;
          float: left;
          margin: 0 0 30px 80px;
          background-color: $options-block-bg;
          padding:  8px 22px;
          li {
               list-style: none;
               width: 100%;
               text-align: left;
               padding: 9px 0;
               border-bottom: 1px solid #fff;
          }
     }
     .facilities-block {
          margin-bottom: 70px;
          h3 {
               @include font-size(22);
               margin-bottom: 1.4em;
               font-weight: 500;
          }
          ul {
               margin: 0;
               li {
                    list-style: none;
                    float: left;
                    width: 25%;
                    @include font-size(15);
                    color: $description-text;
                    font-weight: 300;
                    padding: 0 0 0 1.6em;
                    margin-bottom: .5em;
                    &:before {
                         content: ' ';
                         float: left;
                         margin-left: -1.6em;
						 margin-top: 4px;
                         width: 13px;
                         height: 14px;
                         display: block;
                         background: url('../images/svg/check.svg') center center no-repeat;
                    }
               }
          }
     }
     .location-block {
          position: relative;
          .location {
               background: #fff;
               border-radius: 0 0 5px 5px;
               @include font-size(20);
               padding: .5em 1em;
               position: absolute;
               z-index: 999;
               box-shadow: 0px 3px 5px 0px rgba(1, 1, 1, 0.08);
          }
     }
     #map {
          width: 100%;
          height: 330px;
     }
     .prices-block-outer {
          border-bottom: 1px solid #ececec;
          margin: 60px 0 0;
          .prices-block {
               border-bottom: 1px solid #ececec;
               .prices-block-left, .prices-block-right {
                    width: calc((100% - 30px) / 2);
                    float: left;
                    &:nth-child(1) {
                         margin-right: 30px;
                    }
                    h4 {
                         @include font-size(22);
                         font-weight: 500;
                         margin-bottom: 1.4em;
                    }
                    table {
                         margin-bottom: 24px;
                         tbody {
                              border: none;
                              tr {
                                   &:nth-child(even) {
                                        background-color: #fff; 
                                   }
                                   td {
                                        color: $description-text;
									    font-weight: 300;
                                        padding: 0.25rem 0.312rem 0.312rem;
									   &:nth-child(1) {
										   padding-left: 0;
									   } 
									   &:nth-child(2) {
                                             text-align: right;
                                             font-weight: 500;
                                             color: #000;
										     padding-right: 0.6rem;
                                        }
                                   }
                              }
                         }
                    }
               }
               .prices-block-right {
                    table {
                         float: left;
                         width: calc((100% - 30px) / 2);
                         margin-right: 30px;
                         &:nth-child(3) {
                              margin-right: 0;
                         }
                    }
               }
          }
          .rental-conditions {
               padding: 30px 0 10px;
               @include font-size(14);
			   font-weight: 300;
               p {
                    margin-bottom: 24px;
                    line-height: 1.3em;
                    @include font-size(16);
                    i {
                         font-weight: 700;
                    }
                  
               }
              
          }
     }     
     .availability-block-outer {
          padding: 30px 0 0;
          h4 {
               @include font-size(22);
               font-weight: 500;
               margin-bottom: 1.4em;
          }
     }
     .reservations-block-outer {
          background-color: $reservations-bg;
          padding: 54px 0 60px;
          h2 {
               @include font-size(26);
               font-weight: 600;
               color: $second-color;
               text-transform: uppercase;
               text-align: center;
          }
          & > div > div > p {
               text-align: center;
               margin-bottom: 40px;
          }
     }
}



.sticky-menu-bar {
     position: fixed !important;
     z-index: 999;
     width: 100%;
     padding: 0 calc((100% - 1170px) / 2) 0;
     left: 0;
     top: 0;
     @media screen and (max-width: 1200px) {
          padding: 0 15px;
     }
}

// Sticky menu header offset
:target:before {
     content:"";
     display:block;
     height: 60px; /* fixed header height*/
     margin: -60px 0 0; /* negative fixed header height */
}

@include breakpoint(small only) {
     .detail-page-content {
          .detail-content-menu {
               li {
                    &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6) {
                         display: none;
                    }
                    &:nth-child(7) {
                         margin-right: 5px;
                         float: right;
                    }
               }
          }
          .property-header {
               h1 {
                    margin-bottom: .5em;
               }
               .facebook-block {
                  position: static;
               }
          }
          .photos-block {
               margin-bottom: 0;
               .detail-page-outer {
                    width: 100%;
                    height: auto;
                    margin-bottom: 8px;
                    overflow: auto;
                    img {
                         height: 220px;
                         width: 100%;
                    }
                    .options-block-outer {
                         position: relative;
                         bottom: 0;
                         background-color: #000;
                         opacity: .8;
                         height: auto;
                         .options-block {
                              height: auto;
                              padding: 10px 20px;
                         }
                         .options-block {
                              width: 100%;
                              width: auto;
                              padding: 10px;
                              float: none;
                              & > ul {
                                   text-align: center;
                                   & > li {
                                        @include font-size(15);
                                        margin-right: 8px;
                                        img {
                                             width: 20px;
                                        }
                                        &.total-beds {
                                             img {
                                                  width: 28px;
                                             }
                                        }
                                        .people-text {
                                             display: none;
                                        }
                                   }
                              }
                         }
                         .extras-block {
                             display: none;                            
                         }
                    }                   
               }
               .img-lateral-block {
                    width: 100%;
                    .img-full, .img-half {
                         display: none;
                    }
               }
          }
          .detail-description-block-outer {
               margin-top: 45px;
               width: 100%;
          }
          .description-options-block {
               width: 100%;
               margin-left: 0;
          }
          .facilities-block {
               ul {
                    li {
                         width: 50%;
                    }
               }
          }
          .prices-block-outer {
               .prices-block {
                    .prices-block-left, .prices-block-right {
                         width: 100%;
                         table {
                              margin-right: 0;
                              tbody {
                                   tr {
                                        td {
                                             @include font-size(15);
                                        }
                                   }
                              }
                         }
                         
                    }
                    .prices-block-left {
                         table {
                              tbody {
                                   tr {
                                        td {
                                             &:nth-child(1) {
                                                  width: 105px;
                                             }
                                        }
                                   }
                              }
                         }
                         &:nth-child(1) {
                              margin-right: 0;
                         }
                    }
                    .prices-block-right {
                         table {
                              width: 100%;
                         }
                    }
               }
          }
     }
}

@include breakpoint(medium only) {
     .detail-page-content {
          .detail-content-menu {
               li {
                    margin-right: 1em;
                   a {
                        @include font-size(14);
                   }
               }
          }
          .property-header {
               h1 {
                    margin-bottom: .5em;
               }
               .facebook-block {
                  position: static;
               }
          }
          .photos-block {
               .detail-page-outer {
                    width: 100%;
                    height: 420px;
                    margin-bottom: 8px;
                    img {
                         width: 100%;
                         height: 420px;
                    }
                    .options-block-outer {
                         .options-block {
                              width: 45%;
                              & > ul {
                                   & > li {
                                        @include font-size(15);
                                        margin-right: .5em;
                                        img {
                                             width: 20px;
                                        }
                                        &.total-beds {
                                             img {
                                                  width: 30px;
                                             }
                                        }
                                        .people-text {
                                             display: none;
                                        }
                                   }
                              }
                         }
                         .extras-block {
                              width: 30%;
                              padding: 22px 20px;
                              & > ul {
                                   & > li {
                                        margin-right: 12px;
                                        img {
                                             width: 22px;
                                        }
                                   }
                              }
                         }
                    }
               }
               .img-lateral-block {
                    width: 100%;
                    .img-full, .img-half {
                         display: none;
                    }
               }
          }
          .detail-description-block-outer {
               width: 64%;
          }
          .description-options-block {
               width: 34%;
               margin-left: 2%;
          }          
          .facilities-block {
               ul {
                    li {
                         width: 33%;
                    }
               }
          }
          .prices-block-outer {
               .prices-block {
                    .prices-block-left {
                         width: 100%;
                         table tbody tr td {
                              &:nth-child(3) {
                                   padding-left: 2.5em;
                              }
                         }
                    }
                    .prices-block-right {
                         width: 100%;
                         table {
                              width: calc((100% - 30px) / 2);
                         }
                    }
               }
          }          
     }
}

@media screen and (min-width: 600px) and (max-width:769px) {
     .detail-page-content {
          .detail-content-menu {
               li {
                    &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6) {
                         display: none;
                    }
                    &:nth-child(7) {
                         margin-right: 5px;
                         float: right;
                    }
               }
          }
     }
}

@include breakpoint(large only) {
     .detail-page-content {
          .detail-description-block-outer {
               width: 74%;
          }
          .description-options-block {
               width: 24%;
               margin-left: 2%;
          }
     }
}

@media screen and (min-width:1024px) and (max-width:1216px) {
     .detail-page-content {
          .photos-block {
               .detail-page-outer {
                    width: calc(70% - 8px);
               }
               .img-lateral-block {
                    width: 30%;
               }
          }
     }
}

.read-more-content {
     margin: 1em 0;
     display: none;
     &.close {
          display: block;
     }
}

.disabled { opacity: .5 !important; cursor: not-allowed !important;  }  
.error { border: 1px solid #ae0000 !important; background: #faf3e3 !important;  } 

#reservationCalculation ul {

    padding: 2rem;
   
}