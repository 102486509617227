.home-search-block-outer {
     position: relative;
     .home-search-block {
          width: 1170px;
          margin: 0 calc((100% - 1170px ) / 2);
          position: absolute;
          z-index: 999;
          bottom: 0;
          .banner-title {
               display: block;
               color: #fff;
               @include font-size(28);
               font-weight: 300;
               margin-bottom: .5em;
               h2 {
                    @include font-size(48);
                    font-weight: 700;
                    display: inline;
                    text-shadow: 0px 2px 2px rgba(1, 1, 1, 0.3);
               }
          }
          .form-title {
               margin-top: .8em;
               display: inline-block;
               color: #fff;
               padding: .60em 1em 0.35em 1em;
               background-color: $first-color;
               font-weight: 300;
               border-radius: 3px 3px 0 0;
               @include font-size(16);
          }
     }
}
#search-form {
     display: block;
     background-color: $first-color;
     padding: 9px 13px 4px;
     margin-bottom: 48px;
     border-radius: 0 5px 5px 5px;
     .form-field-left, .form-field, .search-button-outer {
          float: left;
          margin-right: 12px;
          select, input[type="text"] {
               background-color: #fff;
               border: none;
               width: 100%;
               height: 48px;
               margin: 0;
               border-radius: 4px;
               padding: .5em 3em;
               &::placeholder {
                    color: $placeholder-color;
                    font-weight: 600;
               }
               &:hover {
                    cursor: pointer;
               }
          }
     }
     .form-field-left {
          width: 287px;
          position: relative; 
           & > span {
               position: absolute;
               width: 32px;
               height: 37px;
               display: block;
               background: url('../images/svg/marker.svg') center center no-repeat;
               top: 12%;
               left: .25em;
               z-index: 99;
          }   
     }
     .form-field {
          width: 210px;
          &.date-picker {
               position: relative;
               span {
                    position: absolute;
                    display: block;
                    width: 25px;
                    height: 24px;
                    background: url('../images/svg/calendar.svg') top left no-repeat;
                    top: 25%;
                    left: .5em;
               }
               input[type="text"] {
                    &:hover {
                         cursor: pointer !important;
                    }
               }
          }
     }
     .search-button-outer {
          display: block;
          width: 178px;
          margin-right: 0;
          &.hid {
               display: none;
          }
          input[type="submit"] {
               width: 100%;
               border-radius: 5px;
               line-height: 48px;
               padding: 0;
               border: none;
               background-color: $second-color;
               color: #fff;
               @include font-size(22);
               &:hover, &:focus, &:active {
                    background-color: darken($second-color,10%);
                    cursor: pointer;
               }
          }
     }
     .SumoSelect {
          background-color: #fff;
          width: 100%;
          border-radius: 5px;
          height: 47px;
         
          & > .CaptionCont {
               border-radius: 5px;
               border: none;
               height: 48px;
               span {
                    padding: .5em 2.25em;
                    font-weight: 600;
                    &:hover {
                         cursor: pointer;
                    }
                    &.placeholder {
                         color: $placeholder-color;
                         font-style: normal;
                         font-weight: 600;
                    }
               }
          }
          & > .optWrapper {
               z-index: 999999;
               & > .options li.opt {
                    padding-top: 3px;
                    padding-bottom: 3px;
                    margin: 0 .5em;
                    border: none;
               }
          }
          &.open {
               & > .optWrapper {
                    border-radius: 5px;
                    top: 50px;
                    .opt {
                         &:hover {
                              background-color: $first-color;
                              border-radius: 3px;
                              label {
                                   color: #fff;
                              }                             
                         }
                    }
               }
          }
          & > .optWrapper.multiple .options li.selected span > i {
               background-color: darken($first-color, 10%);
          }
          & > .CaptionCont > label > i {
               width: 20px;
               height: 20px;
               background-image: url('../images/svg/angle_down.svg');
          }
     }
     & > div:nth-child(4) {
          position: relative; 
          & > span {
               position: absolute;
               display: block;
               width: 30px;
               height: 35px;
               background: url('../images/svg/user_icon.svg') center center no-repeat; 
               top: 12%;
               left: .5em;
               z-index: 99;
          }
          .SumoSelect > .CaptionCont span {
               font-weight: 600;
               padding: .5em 2.25em;
          }       
     }
}

.listing-search-form-outer {
     background-color: $first-color;
     position: relative;
     #search-form {
          margin: 22px 0;
          #swimming-pool-block {
               position: relative;
               display: none;
               width: 178px;
               margin-right: 0;
               &.changed {
                    display: block;
               }
               & > span {
                    position: absolute;
                    display: block;
                    width: 30px;
                    height: 26px;
                    background: url('../images/svg/swimming_pool.svg') center center no-repeat;
                    top: 25%;
                    left: .5em;
                    z-index: 99;
               }
          }
          .more-options-block {
               display: none;
               margin-top: 25px;
               .form-field {
                    position: relative;
                    margin-right: 0;                   
               }
               .checkbox-block {
                    width: 190px;
                    float: left;
                    margin: 0 32px 0 0;
                    input[type="checkbox"] {
                         display: none;                        
                         & + label span {
                              display: inline-block;
                              width: 18px;
                              height: 18px;
                              margin: -1px 13px 0 0;
                              vertical-align: middle;
                              border-radius: 3px;
                              background: #fff;
                              cursor: pointer;
                         }
                         &:checked + label span {
                              background: #fff url('../images/svg/checked.svg') left top no-repeat;
                         }
                     }
                     label {
                          width: 100%;
                         @include font-size(16);
                         color: #fff;
                         margin: 0;
                     }
               }
               & > .search-button-outer {
                    float: right;
                    input {
                         height: 84px;
                    }
               }
          }
     }
     .options-button-block {
          position: absolute;
          content: ' ';
          background: transparent url('../images/svg/tab.svg') center top no-repeat;
          width: 166px;
          height: 45px;
          display: block;
          bottom: -43px;
          left: calc((100% - 166px) / 2);
          &:hover {
               cursor: pointer;
          }
          p {
               text-align: center;
               @include font-size(12);
               color: #fff;
               margin:0;
               &.close-text {
                    display: none;
               }
          }
          img {
               display: block;
               width: 20px;
               height: 23px;
               margin: 0 auto;
          }
     }
}

#reservationForm {
     position: relative;
     width: 100%;
     .left-block, .right-block {
          width: calc((100% - 30px) / 2);
     }
     .left-block {
          float: left;
          margin-right: 30px;
     }
     .right-block {
          float: right;
          overflow: visible;
     }
	.margin-top{
		  margin-top: 20px;	
	}
     h5 {
          @include font-size(18);
          font-weight: 500;
          padding: 1.18em 0 .9em;
          margin-bottom: 0;
     }
     .travel-details-block, .supplements-block, .price-calculation-block {
          width: 100%;
     }
     .travel-details-block, .supplements-block, .personal-details-block {
          h5 {
               &:before {
                    content: '1';
                    @include font-size(24);
                    padding: .2em .65em;
                    color: $first-color;
                    float: left;
                    margin-left: -4.5em;
                    margin-top: -.55em;
                    border: 5px solid $first-color;
                    border-radius: 1.5em;
               }
          }
		 label{
			 color: $placeholder-color;
		 }
     }
     .price-calculation-block {
          padding: 0 0 30px;
          & > div {
               background-color: #fff;
               border-radius: 3px;
               overflow: hidden;
               -webkit-box-shadow: 0px 0px 8px 2px rgba(126, 126, 126, 0.2);
               -moz-box-shadow: 0px 0px 8px 2px rgba(126, 126, 126, 0.2);
               box-shadow: 0px 0px 8px 2px rgba(126, 126, 126, 0.2);
               h5 {
                    padding-left: 15px;
               }
               table {
                    margin-bottom: 0;
                    tbody {
                         background-color: transparent;
                         border: none;
                         tr {
                              &#total-cost,&#repayable-deposit {
                                   background-color: $total-cost-bg;
                              }
                              &#total-cost {
                                   margin-top: 11px;
                                   td {
                                        padding-top: 1.125em;
                                        font-weight: 600;
                                   }                                  
                              }
                              &#repayable-deposit {                                   
                                   td {
                                        padding-bottom: .875em;
                                   }
                                 
                              }
                              &:nth-child(even) {
                                   background-color: transparent;
                              }
                              td {
                                   padding: 0.435em 0.9375em;
                                   &:nth-child(2) {
                                        text-align: right;
                                        padding-right: 1.5625em;
                                   }
                              }
                         }
                    }
               }
          }
     }
     .travel-details-block {
          border-bottom: 1px solid $web-link;
          padding-bottom: 25px;
     }
     .half-field {
          width: calc((100% - 15px) / 2);
          float: left;
          margin: 0 15px 15px 0;
          &:nth-child(3n) {
               margin-right: 0;
          }
          &.right {
               width: calc((100% - 30px) / 2);
               float: right;
               margin-right: 0;
          }
          &.date-picker {
               position: relative;
               &::after {
                    position: absolute;
                    top: 18%;
                    left: 10px;
                    content: '';
                    width: 26px;
                    height: 26px;
                    display: block;
                    background: url('../images/svg/calendar.svg') top left no-repeat;
               }
          }
     }
     .large-field {
          width: 100%;
          margin-bottom: 15px;
          input[type='checkbox'] {
               display: none;
               & + label {
                    margin:  0 1em 16px 0;
                    span {
                         display: inline-block;
                         width: 18px;
                         height: 18px;
                         margin: -1px 6px 0 0;
                         vertical-align: middle;
                         border-radius: 3px;
                         border: 1px solid $web-link;
                         background: #fff;
                         cursor: pointer;
                    }
                    a {
                         color: $font-color;
                         text-decoration: underline;
                    }
               }
               &:checked + label span {
                    &:after {
                         content: '';
                         width: 10px;
                         height: 10px;
                         margin: 3px;
                         display: block;
                         background-color: $first-color;
                         border-radius: 2px;
                    }
               } 
          }
     }
     .SumoSelect {
          width: 100%;
     }
     input[type="text"], textarea, .SumoSelect > .CaptionCont {
          box-shadow: none;
          background-color: #fff;
         border: 2px solid $input-border;
         border-radius: 4px;
         height: 40px;
         margin-bottom: 0;
         padding-left: 12px;
		 @include font-size(15);
		 color: $placeholder-color;
         &:hover {
               cursor: pointer;
          }
         &::placeholder, span {
              color: $placeholder-color;
              font-weight: 400;
			  padding-top: 1px;
			  @include font-size(15);
         }
         & > span {
              text-align: left;
              &:hover {
                    cursor: pointer;
               }
         }
     }
     input[type='text'] {
          &#arrival-date,&#departure-date {
               padding-left: 42px;
          }
     }
     textarea {
          height: 130px;
          margin-bottom: 16px;
     }
     .SumoSelect {
          & > .CaptionCont > label > i {
               width: 20px;
               height: 20px;
               background-image: url('../images/svg/angle_down.svg');
          }
          &.open {
               & > .optWrapper {
                    top: 40px;
               }
          }
          & > .optWrapper > .options li.opt {
               padding-top: 3px;
               padding-bottom: 3px;
               margin: 0 .5em;
               border-bottom: none;
          }
     }
     .supplements-block {
          padding: 0 0 40px;
          h5 {
               &::before {
                    content: '2';
               }
          }
          input[type="checkbox"] {
               display: none;                        
               & + label span {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin: -1px 13px 0 0;
                    vertical-align: middle;
                    border-radius: 3px;
                    border: 1px solid $web-link;
                    background: #fff;
                    cursor: pointer;
               }
               &:checked + label span {
                    background: #fff url('../images/svg/checked.svg') left top no-repeat;
               }
               &:disabled:checked + label {
                    span {
                         background: #fff url('../images/svg/checked_disabled.svg') left top no-repeat;
                    }
                    &:hover {
                         cursor: not-allowed;
                    }
               }
          }
          label {
                width: 100%;
               @include font-size(16);
               font-weight: 400;
               margin: 0;
          }
     }
     .personal-details-block {
          width: 100%;
          border-top: 1px solid $web-link;
          h5 {
               &:before {
                    content: '3';
               }
          }
          .left-block, .right-block {
               width: calc((100% - 30px) / 2);
               float: left;
               .half-field {
                    margin: 0 15px 12px 0;
               }
               .large-field {
                    margin-bottom: 12px;
               }
          }
          .left-block {
               margin-right: 30px;
               .half-field {
                    &:nth-child(2n) {
                         margin-right: 0;
                    }
               }
          }
          .right-block {
               .half-field {
                    &:nth-child(3n) {
                         margin-right: 0;
                    }
               }
          }
         
     }
     input#submitButton {
          width: 100%;
          border-radius: 3px;
          background-color: $second-color;
          border: none;
          text-transform: uppercase;
          padding: 16px 20px;
          color: #fff;
          &:hover, &:focus {
               background-color: darken($second-color, 10%);
               cursor: pointer;
          }
     }
        
}

// Favorites form styles 
#favorites-form {
     display: none;
     margin-bottom: 1em;
     input[type="text"], input[type="email"], textarea {
          box-shadow: none;
          background-color: #fff;
          border: 2px solid $input-border;
          border-radius: 4px;
          height: 40px;
          margin-bottom: 0;
          padding-left: 12px;
          font-size: 15px;
          font-size: .9375em;
          font-size: .9375rem;
          color: $placeholder-color;
          &::placeholder {
               color: $placeholder-color;
               font-weight: 400;
               padding-top: 1px;
               @include font-size(15);
          }
          &:hover {
               cursor: text;
          }
     }
     textarea {
          height: 130px;
          margin-bottom: 16px;
     }
     input[type="submit"] {
          width: 100%;
          border-radius: 3px;
          background-color: $second-color;
          border: none;
          text-transform: uppercase;
          padding: 16px 20px;
          color: #fff;
          &:hover, &:focus {
               background-color: darken($second-color, 10%);
               cursor: pointer;
          }
     }
     .field-out {
          position: relative;
          float: left;
          width: calc(50% - 6px);
          margin: 0 12px 12px 0;
          &:nth-child(3),&:nth-child(5) {
               margin-right: 0;
          }
          b {
               color: $second-color;
               position: absolute;
               top: 25%;
               right: 12px;
          }
     }
     input[type='checkbox'] {
          display: none;
          & + label {
               margin:  0 0 16px 0;
               span {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin: -1px 14px 0 0;
                    vertical-align: middle;
                    border-radius: 3px;
                    border: 1px solid $web-link;
                    background: #fff;
                    cursor: pointer;
               }
               a {
                    color: $font-color;
                    text-decoration: underline;
               }
          }
          &:checked + label span {
               &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    margin: 3px;
                    display: block;
                    background-color: $first-color;
                    border-radius: 2px;
               }
          } 
     }
     a {
          color: #4a4a4a;
          text-decoration: underline;
     }
}

@include breakpoint(small only) {
     .home-search-block-outer, 
     .listing-search-form-outer {
          .home-search-block {
               width: 95%;
               margin: 0 2.5%;
               .banner-title {
                    display: block;
                    @include font-size(19);
                    text-align: center;
                    margin-bottom: 50px;
                    h2 {
                         @include font-size(33);
                         text-align: inherit;
                         display: inherit;
                         line-height: .8em;
                    }
               }
          }
          #search-form {
               .form-field-left {
                    width: 100%;
                    margin-bottom: 9px;
               }
               .date-picker {
                    width: 48%;
                    margin-right: 4%;
                    margin-bottom: 9px;
                    &:nth-child(3) {
                         margin-right: 0;
                    }
               }
               .form-field {
                    width: 48%;
                    margin-right: 4%;
               }
               .search-button-outer {
                    width: 48%;
               }
               #swimming-pool-block {
                    width: 48%;
               }
               .more-options-block {
                    .checkbox-block {
                         width: 49%;
                         margin: 0 1% 18px 0;
                         &:nth-child(2n) {
                              margin-right: 0;
                         }
                         label {
                              @include font-size(14);
                         }
                    }
                    & > .search-button-outer {
                         width: 100%;
                         margin-right: 0;
                         input {
                              height: 48px;
                         }
                    }
               }
          }
     }
     #reservationForm {
          .left-block, .right-block {
               width: 100%;
          }
          .price-calculation-block {
               max-width: 100%;
          }
          .personal-details-block {
               .left-block, .right-block {
                    width: 100%;
               }
          }
          .half-field.right {
               width: 100%;
          }
     }
     #favorites-form {
          .field-out {
               width: 100%;
               margin-right: 0 !important;
          }
     }
     #viviendas .listing-search-form-outer #search-form .date-picker {
          &:nth-child(3) {
               margin-right: 4%;
          }
          &:nth-child(4) {
               margin-right: 0;
          }
     }
}

@include breakpoint(medium only) {
     .home-search-block-outer, 
     .listing-search-form-outer {
          .home-search-block {
               width: 95%;
               margin: 0 2.5%;
               .banner-title {
                    display: block;
                    h2 {
                         line-height: .6em;
                         display: inherit;
                    }
               }
               .form-title {
                    margin-top: .6em;
               }
          }
          #search-form {
               .form-field-left {
                    width: 100%;
                   margin-bottom: 9px;
               }
               .date-picker, .form-field, .search-button-outer {
                    width: 23.72%;
                    margin-right: 1.7%;
                    margin-bottom: 0;
               }
               .search-button-outer {
                    margin-right: 0;
               }
               #swimming-pool-block {
                    width: 23.72%;
               }
               .more-options-block {
                    .form-field {
                         width: 100%;
                         margin-bottom: 18px;
                    }
                    .checkbox-block {
                         width: 24%;
                         margin: 0 1.33333% 20px 0;
                         &:nth-child(4n) {
                              margin-right: 0;
                         }
                         label {
                              @include font-size(14);
                         }
                    }
                    & > .search-button-outer {
                         margin-right: 0;
                         input {
                              height: 48px;
                         }
                         
                    }
               }
          }
     }
}

@media screen and (min-width: 61.23em) and (max-width: 81.1875em) {
     .home-search-block-outer, 
     .listing-search-form-outer {
          .home-search-block {
               width: 95%;
               margin: 0 2.5%;
               .banner-title {
                    display: block;
                    h2 {
                         line-height: .6em;
                         display: inherit;
                    }
               }
               .form-title {
                    margin-top: .6em;
               }
          }
          #search-form {
               .form-field-left {
                    width: 100%;
                   margin-bottom: 9px;
               }
               .date-picker, .form-field, .search-button-outer {
                    width: 23.72%;
                    margin-right: 1.7%;
                    margin-bottom: 0;
               }
               .search-button-outer {
                    margin-right: 0;
               }
               #swimming-pool-block {
                    width: 23.72%;
               }
               .more-options-block {
                    .form-field {
                         width: 100%;
                         margin-bottom: 18px;
                    }
                    .checkbox-block {
                         width: 18%;
                         margin: 0 1.33333% 0 0;
                         &:nth-child(4n+1) {
                              margin-right: 0;
                         }
                         label {
                              @include font-size(14);
                         }
                    }
                    & > .search-button-outer {
                         margin-right: 0;
                         input {
                              height: 75px;
                         }
                         
                    }
               }
          }
     }
}
