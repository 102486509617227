.off-canvas {
     display: none;
     &.is-open {
          display: block;
          z-index: 999999;
     }
     &.in-canvas-for-medium {
          .close-button {
               display: block;
          }
     }
}
.right-off-canvas-menu{
     float: left;
     width: 100%;
     .language-switcher {
          position: relative;
          float: right;
          margin: 1em 2.5em 1em 0;
          & > a {
               & > img {
                    width: 32px;
                    height: 19px;
                    margin-bottom: 6px;
               }
               &:after {
                    content: " ";
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../images/svg/angle_down.svg') center center no-repeat;
                    position: absolute;
                    right: -1.5em;
                    top: .125em;
               }
          }
          ul {
               list-style: none;
               position: absolute;
               left:0;
               z-index: 9999;
               margin: 0;
               li {
                    padding: 0;
                    img {
                         width: 32px;
                         height: auto;
                         margin-bottom: 5px;
                    }
               }
          }
     }
     .header-contact {   
         padding: 30px 2px 50px 15px;
         ul{
             text-align: center;
         }
         li {
             width: 100%;
             float: left;
             list-style: none;
             margin: 0;
             padding: 0;
             color: $font-color;
             padding: 0 12px 0 14px;
             text-align: center;
             &:first-child {
                 background: none;
             }
             &.email a {
                 color: $font-color;
                 &:hover {
                    color: darken($second-color, 10%);
                 }
             }
             a {
                 width: auto;
                 float: none;
                 color: $font-color;
                 margin: 0 0 0 4px;
                 &:hover {
                    color: darken($second-color, 10%);;
                 }
             }
         }
     }
     .header-social{
         width: 100%;
         padding: 22px 30px;
     }
 }
 .mobile-nav{
     width: 100%;
     padding: 0 1em 0;
     ul{
          margin: 0;
         li{
              list-style: none;
             width: 100%;
             float: left;
             border-bottom: 1px solid #fff;
             a{
                 width: 100%;
                 display: inline-block;
                 font-family: $font-family;
                 color: lighten($first-color, 45%);
                 text-transform: uppercase;
                 padding: 11px 0 9px;
                 line-height: 25px;
                 i{
                     float: right;
                     padding-top: 4px;
                 }
             }
             &:hover > a,
             &.current-item a,
             &.active > a {
                 color: darken($second-color, 10%);
             }
             ul.submenu{
                 margin: 0;
                 display: none;
                 li{
                     border-bottom: 0;
                     a{
                        padding: 6px 22px 6px;
                        font-size: 14px;
                     }
                 }
             }
         }
     }
 }
 .multi-menu {
     margin-top: 15.5em;
     width: 100%;
     & > li {
          padding: .5em 1.5em;
          list-style: none;
          display: inline;
          & > ul {
               list-style: inherit;
               & > li {
                    display: block;
               }
          }
      }
 }
 .mobile-facebook-link {
      float: right;
      padding: .5em .75em;
      margin-right: .5em;
      a {
          @include font-size(24);
          line-height: inherit;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          &:hover {
               color: #1779ba;
          }
      }
 }
 .language-mobile{
     background: $first-color;
     padding: 10px 30px;
     width: 100%;
     float: left;
      li{ width: auto;float: left;padding-left: 30px;
          &:first-child{
              padding-left: 0;
          }
     }
     a{
          float: left; 
          color: $font-color;
         &:hover,
         &:focus{
             color: darken($second-color, 10%);
         }
     }
 }
 .close-button {
     left: .5em;
     top: .3em;
     i {
          color: #fff;
     }
 }

#header {
    .js-off-canvas-overlay.is-overlay-fixed.is-visible.is-closable {
        position: fixed;
        background: rgba(48, 48, 48, 0.8);
        z-index: 10000;
        opacity: 1;
        visibility: visible;
    }
     .header-top-inner {
          position: relative;
          height: 120px;
          .logo {
               display: block;
               position: absolute;
               margin: 1.85em 0;
               top: 0;
               left: 0;
               a {
                    color: $font-color;
                    span {
                         @include font-size(40);
                         font-weight: 500;
                         letter-spacing: -.05em;
						 color: $logo;
                         i {
                              font-weight: 300;
                              color: #9e9e9e;
                              font-style: normal;
                         }
                    }
               }
          }
          .main-menu {
               display: block;
               width: 720px;
               margin: 0 calc((100% - 720px) / 2);
               float: left;
               ul {
                    text-align: center;
                    width: 100%;
                    margin: 2.8em 0;
                    padding: 0;
                    li {
                         display: inline;                       
                         margin: 0 1em;
                         a {
                              text-transform: uppercase;
                              &:hover,&:active, &:focus {
                                   color: darken($second-color, 10%);
                              }
                         }
                         &.active {
                              a {
                                   color: darken($second-color, 10%);
                                   &:before {
                                        color: darken($second-color, 10%);
                                   }
                              }
                             
                         }
                         &:first-child {
                              position: relative;
                              a {  
                                   display: inline-block;
                                   position: absolute;
                                   bottom: -.25em;
                                   left: -1.75em;
                                   width: 34px;
                                   height: 34px;
                                   background: url('../images/svg/home_active_icon.svg') center center no-repeat;
                                   &:hover, &:active {
                                        &:before {
                                             color: darken($second-color, 10%);
                                        }
                                   }
                                   span {
                                        display: none;
                                   } 
                              }
                         }
                         &:last-child {
                              background-color: $first-color;
                              padding: 0.75em 1.5em 0.55em 1.5em;
                              border-radius: 3px;
                              margin-right: 0;
                              a {
                                   color: #fff;                                  
                              }
                              &:hover {
                                   background-color: darken($second-color, 10%);
                              }                              
                         }
                         a {
                              @include font-size(18);
                              color: $font-color;
                         }
                    }
               }
          }
          .multi-menu {
               position: absolute;
               top: 0;
               right: 0;
               list-style: none;
               margin: 2.55em 2.5em 2.55em 0;
               width: auto;
               height: 37px;
               & > li {
                    line-height: 37px;
                    display: inline;
                    padding: .5em 1.25em;
                    border-right: 1px solid #dedede;
                    &.hide-for-xlarge  {
                         padding: .05em 1.25em;
                    }
                    &:last-of-type {
                         border: none;
                    }
                    a {
                         i {
                              @include font-size(24);
                              color: #959494;
                         }
                         &:hover, &:active, &:focus {
                              i {
                                   color: darken($second-color, 10%);    
                                   &.fa-facebook {
                                        color: #3b5998;
                                   }     
                              }       
                         } 
                         &.mobile-icon {
                              i {                                  
                                   color: $first-color;
                              }
                         }                         
                    }
                    &:nth-child(2) {                       
                         & > a {   
                              position: relative;                          
                              & > span {
                                   width: 20px;
                                   height: 20px;
                                   display: block;
                                   line-height: 20px;
                                   position: absolute;
                                   @include font-size(12);
                                   background-color: $third-color;
                                   color: #fff;
                                   text-align: center;
                                   border-radius: 12px;
                                   top: -.1em;
                                   right: -.8em;
                              }                            
                         }  
                    }
                    .language-switcher {
                         position: relative;
                         float: right;
                         & > a {
                              & > img {
                                   width: 32px;
                                   height: 19px;
                                   margin-bottom: 6px;
                              }
                              &:after {
                                   content: " ";
                                   display: block;
                                   width: 20px;
                                   height: 20px;
                                   background: url('../images/svg/angle_down.svg') center center no-repeat;
                                   position: absolute;                                
                                   right: -1.25em;
                                   top: .5em;                                   
                              }
                         }
                         ul {
                              width: 45px;
                              list-style: none;
                              position: absolute;
                              left:-.45em;
                              z-index: 9999;
                              margin: 0;
                              background-color: #fff;
                              padding: .5em .5em;
                              border-radius: 3px;
                              li {
                                   padding: 0;
                                   margin-bottom: .25em;
                                   &:last-child {
                                        margin-bottom: 0;
                                   }
                                  img {
                                       width: 100%;
                                       height: auto;
                                  }
                              }
                         }
                    }
               }
          }          
     }
}

.favorite-block-outer {
     background-color: transparent;
     top: 100px;
     right: 100px;
     display: none;
     width: 450px;
     position: absolute;
     z-index: 9999;
     .favorite-block {
          height: auto;
          max-height: 362px;
          overflow-y: auto;
          color: $font-color;
          background-color: #fff;
          padding: .5em;
          border-radius: 5px;
          border: 1px solid #ccc;
          box-shadow: 0px 5px 5px 0px rgba(46,46,46,0.25);
          #send-favorites-btn {
               width: 50%;
               padding: .5em 1em;
               margin: 0 25% 1em;
               text-transform: uppercase;
               color: #fff;
               background-color: $second-color;
               @include font-size(14);
               border-radius: 3px;
               &:hover {
                    cursor: pointer;
                    background-color: darken($second-color,20%);
               }
          }
          #close-favourites-form {
               display: none;
               span {
                    @include font-size(24);
                    cursor: pointer;
               }
          }
          .favorite-thumbnail {
               position: relative;
               background-color: #fff;
               border-bottom: 1px solid #ccc;
               width: 100%;
               float: left;
               margin-bottom: 12px;   
               overflow: hidden;
               .remove-favorite-thumbnail {
                    position: absolute;
                    background-color: rgb(194, 11, 24);
                    color: #fff;
                    padding: .1em .35em;
                    left: 0;
                    &:hover {
                         cursor: pointer;
                    }
               }
               img {
                    float: left;
                    width: 125px;
                    height: auto;
               }
               .favorite-content {
                    width: calc(100% - 125px);
                    float: right;
                    padding: .5em;
                    h3 {
                         @include font-size(16);
                    }
                    .location {
                         @include font-size(14);                        
                         font-weight: 600;
                         line-height: 29px;
                         float: left;
                    }
                    ul {
                         margin: 0 0 0 1em;
                         float: left;
                         li {
                              display: inline-block;
                              list-style: none;
                              line-height: 26px;
                              @include font-size(13);
                              img {
                                   width: 26px;
                                   height: auto;
                                   margin: 0 6px;
                              }
                              &:last-child {
                                   img {
                                        width: 18px;
                                        height: 26px;
                                   }
                              }
                         }
                    }
               }
          }
     }
} 

@include breakpoint(small only) {
     #header {
          .header-top-inner {
               .logo {
                    width: 55%;
                    margin: 2.45em 0;
                    a {
                         span {
                              @include font-size(29);
                         }
                    }
               }
               .multi-menu {
                    margin-right: 0;
                    & > li {
                         padding: .5em 1em;
                    }
               }
          }
     }
     #favorites-list-block {
          right: 0;
          width: 100%;
          .favorite-block {
               height: auto;
          }
     }
}

.off-canvas.position-right.is-transition-overlap.is-open {-webkit-transform: translateX(360px);-ms-transform: translateX(360px);transform: translateX(360px);}
.js-off-canvas-overlay.is-visible {opacity:0;visibility: hidden;}

 @include breakpoint(small only) {
     .off-canvas{background: lighten($first-color,15%);}
     .title-bar {
          padding: 4px 0px 0 8px;
          position: relative;
          top: -40px;
     }
}

@media screen and (min-width:1024px) and (max-width:1309px) {
     #header {
          .header-top-inner {
               .logo {
                    margin: 1.95em 0;
                    a span {
                         @include font-size(31);
                    }
               }
               .main-menu {
                    margin-left: 23%; 
                   ul {
                        margin: 3em 0;
                        li {
                             margin: 0 .25em;
                             a {
                                  @include font-size(16);
                             }
                        }
                   }
               }
          }
     }
}

@media screen and (max-width:1350px) {
     #header {
          .header-top-inner {
               .main-menu {
                    display: none;
               }
               .multi-menu {
                    & > li {
                         &.mobile-nav-button {
                              display: inline-block;
                         }
                    }
               }

          }
     }
}