/*
=================
* Custom styles *
=================
*/

// Contact form sytles
#contacto {
     .inner-wrapper {
          margin: 2em 0;
     }
     .property-wrapper {
          & > .row > .medium-4 {
               margin-top: 60px;
          }
          h2 {
               margin-top: 1.5em;
               @include font-size(18);
          }
          h3 {
               @include font-size(17);
          }
          a {
               color: $font-color;
               &:hover, &:active {
                    color: darken($second-color, 10%);
               }
          }
     }
     #contactoForm {
          margin: 2.5em 0 1.5em;
          .half-field {
               position: relative;
               float: left;
               width: calc((100% - 15px) / 2);
               margin: 0 15px 12px 0;
               &:nth-child(2n) {
                    margin-right: 0;
               }
               span {
                    color: $second-color;
                    position: absolute;
                    top: 25%;
                    right: 12px;
               }  
               @include breakpoint(small only) {
                    width: 100%;
                    margin-right: 0;
               }            
          }
          input[type="text"], textarea {
               box-shadow: none;
               background-color: #fff;
               border: 2px solid $input-border;
               border-radius: 4px;
               height: 40px;
               margin-bottom: 0;
               padding-left: 12px;
               font-size: 15px;
               font-size: .9375em;
               font-size: .9375rem;
               color: $placeholder-color;
               &::placeholder {
                    color: $placeholder-color;
                    font-weight: 400;
                    padding-top: 1px;
                    @include font-size(15);
               }
               &:hover {
                    cursor: pointer;
               }
          }
          textarea {
               height: 130px;
               margin-bottom: 16px;
          }
          input[type='checkbox'] {
               display: none;
               & + label {
                    margin:  0 1em 16px 0;
                    span {
                         display: inline-block;
                         width: 18px;
                         height: 18px;
                         margin: -1px 8px 0 0;
                         vertical-align: middle;
                         border-radius: 3px;
                         border: 1px solid $web-link;
                         background: #fff;
                         cursor: pointer;
                    }
                    a {
                         color: $font-color;
                         text-decoration: underline;
                    }
               }
               &:checked + label span {
                    &:after {
                         content: '';
                         width: 10px;
                         height: 10px;
                         margin: 3px;
                         display: block;
                         background-color: $first-color;
                         border-radius: 2px;
                    }
               } 
          }
          input[type="submit"] {
               width: 100%;
               border-radius: 3px;
               background-color: $second-color;
               border: none;
               text-transform: uppercase;
               padding: 16px 20px;
               color: #fff;
               &:hover, &:focus {
                    background-color: darken($second-color, 10%);
                    cursor: pointer;
               }
          }
     }
     #map {
          height: 330px;
     }
}

// Content general styles
.content {
     a {
          color: $font-color;
          text-decoration: underline;
          &:hover, &:focus {
               color: darken($second-color, 10%);
          }
     }
}

// Favourite tag styles 
.toggle_favoritos {
     background-color: rgba(0,0,0,0.5);
     &.favorito {
          background-color: rgba(129,30,30,0.9) !important;
     }
}

#favorites-list-block {
     .toggle_favoritos {
          background-color: transparent;
     }
}

// Read more blocks 
.detail-page-content {
     .detail-description-block-outer {
          & > div {
               overflow: hidden;            
          }
          .read-more {
               position: relative;
               bottom: 1.7em;
          }
     }    
}

#login-form {
     input[type="text"],
     input[type="password"] {
          box-shadow: none;
          background-color: #fff;
          border: 2px solid #e6e4e4;
          border-radius: 4px;
          height: 40px;
          margin-bottom: 0;
          padding-left: 12px;
          font-size: 15px;
          font-size: .9375em;
          font-size: .9375rem;
          color: #505050;
          margin-bottom: 12px;
     }
     input[type="submit"] {
          width: 100%;
          border-radius: 3px;
          background-color: #f16803;
          border: none;
          text-transform: uppercase;
          padding: 16px 20px;
          color: #fff;
          &:hover {
               cursor: pointer;
          }
     }
}

// Calendar styles
.datepicker-block-outer #datepicker-block .ui-datepicker-inline .ui-datepicker-group table tbody tr td.booked {
     background: rgba($second-color,.4);
     span {
          color: #fff;
     }
}
.ui-datepicker-calendar {
     tbody {
          tr {
               td {
                    &.booked {
                         background: rgba(241,104,3,0.4);
                    }
                    &.arrival {
                         background: transparent url(../images/calendar/arrival.svg) right bottom no-repeat !important;
                         a.ui-state-default {                            
                              background: transparent;
                         }
                    }
                    &.departure {
                         background: transparent url(../images/calendar/departure.svg) left top no-repeat !important;                 
                         a {
                              background: transparent;
                         }
                    }
               }
          }
     }
}

#ui-datepicker-div {
     tbody {
          tr {
               &:nth-child(even) {
                    background-color: #fff;
               }
               td {
                    &.booked {
                         background: rgba(241,104,3,0.4);
                        
                    }
                    &.ui-state-disabled {
                         &.booked {                         
                              span {
                                   color: #fff;
                              }                              
                         }
                    }
                    &.arrival {
                         background: transparent url(../images/calendar/arrival.svg) bottom right no-repeat !important;
                         background-size: contain !important;
                         a.ui-state-default {                            
                              background: transparent;
                         }
                    }
                    &.departure {
                         background: transparent url(../images/calendar/departure.svg) top left no-repeat !important;  
                         background-size: contain !important;               
                         a {
                              background: transparent;
                         }
                    }
               }
          }
     }
}

// Content pages styles
.content-page {
     margin-bottom: 60px;
     h1, h2, h3 {
          margin: .5em 0 1.5em;
     }
     h1 {
          margin-bottom: 1em;
     }
     h2 {
          margin-top: 1.125em;
          @include font-size(22);
     }
     ul {
          margin-bottom: 1.5em;
          li {
               margin-bottom: .25em;
          }
     }
}

#header {
     .header-top-inner {
          .multi-menu {
               & > li {
                    &.hide-for-xlarge {
                         display: none;
                    }
               }
          }
     }
}

.show-for-xlarge {
     display: block;
     .favourites-link-outer {
          display: inline-block;
     }
     .language-switcher {
          display: inline;
     }
}

@media screen and (max-width:74.9375em) {
     #header {
          .header-top-inner {
               .multi-menu {
                    & > li {
                         &.hide-for-xlarge {
                              display: inline-block;
                              border-right: none;
                         }
                         &.show-for-xlarge {
                              display: none;
                         }
                    }
               }
          }
     }
     .show-for-xlarge {
          display: none;
     }
}

@media screen and (max-width:74.9375em) {
     .off-canvas {
          &.in-canvas-for-medium {  
               visibility: visible;
               position: fixed;
               background: $first-color;
               -webkit-transition: transform 0.5s ease;
               -moz-transition: transform 0.5s ease;
               transition: transform 0.5s ease;
               height: 100%;
               overflow-y: auto;
               width: 360px;
               -webkit-transform: translateX(360px);
               -moz-transform: translateX(360px);
               transform: translateX(360px);
               
          }
          &.position-right {
               &.is-transition-overlap {
                    &.is-open {
                         webkit-transform: translate(0, 0);
                         -moz-transform: translate(0, 0);
                         transform: translate(0, 0);
                    }
               }             
          }
     }
}
.video-modal-outer {
     position: absolute;
     visibility: hidden;
     z-index: 9998;
     top: 0;
     left: 0;
     background-color: rgba(#000,.8);
     #video-modal {        
          background: #fff;
          padding: 10px 20px;
          position: absolute;
          z-index: 9999;
          width: 1023px;
          height: auto;
          border: 1px solid #cdcdcd;
          border-radius: 6px;
          .close-block {
               position: relative;
               height: 40px;
               .close-video-modal {
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include font-size(32);
                    color: $first-color;
                    &:hover {
                         cursor: pointer;
                    }
               }
          }
     }
}
@include breakpoint(small only) {
     .video-modal-outer {
          #video-modal {
               width: 100%;
          }
     }
}
@include breakpoint(medium only) {
     .video-modal-outer {
          #video-modal {
               width: 100%;
          }
     }   
}

// Safari search form fix
#inicio {
     #search-form {
          &.safari {
               padding: 9px 13px 8px;
          }
     }
}

// Ajax calendar styles
#ajax-calendar {
     .datepicker-block-outer a#prev-months i,
     .datepicker-block-outer a#next-months i {
          line-height: 60px;
     }
}

td.cursive {
     font-style: italic;
}

// Fin language menu fix
#header {
    &.fi {
        .header-top-inner .main-menu ul li a {
            @include font-size(16);
        }
    }    
}

.listing-properties-block .listing-properties-elements>ul>li .image-block {
    &:hover {
        .more-info {
            transition: all .5s ease-out;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            h4 {                    
                 color: #fff;
                 margin-top: 30%;
            }
        }
    }
}