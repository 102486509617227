.content-block {
     h3 {
          text-align: center;
          font-weight: 600;
          margin: 2em 0;
          @include font-size(36);
     }
     .park-large, .park-small {
          position: relative;
          display: block;
          background-color: #cdcdcd;
          float: left;
          overflow: hidden;
          border-radius: 5px;     
          &:hover {
               img {
                    transition: all .5s ease-in-out;
                    transform: scale(1.1);
               }
          }
          .park-gradient {
               position: absolute;
               top: 0;
               bottom: 0;
               left: 0;
               width: 100%;
               z-index: 99;
               background: -moz-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
               background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
               background: linear-gradient(to top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
          img {
               width: 100%;
               object-fit: cover; 
               transition: all .5s linear;             
          }
          .park-title-block {
               position: absolute;
               left: 0;
               bottom: 0;
               padding: 20px;
               width: 100%;
               z-index: 999;
               strong {
                    text-shadow: 0px 1px 1px rgba(1, 1, 1, 0.3);
                    font-weight: bold;
                    color: #fff;
                    display: block;
               }
               .park-situation {
                    background-color: $situation-tag;
                    font-weight: 400;
                    @include font-size(14);
                    color: #fff;
                    padding: .125em .25em;
                    border-radius: 3px;
                    width: auto;
               }
               i {
                    color: #fff; 
                    position: absolute;  
                    right: .35em;                     
               }
          }
     }
     .park-large {
          width: calc((100% - 18px) / 2);
          margin: 0 18px 18px 0;
          height: 300px;
          img {
               height: 300px;
          }
          .park-title-block {
               strong {
                    @include font-size(28);
               }              
               i {
                    @include font-size(52);
                    top: 35%; 
               }
          }
     }
     .park-small {
          width: calc((100% - 36px) / 3);
          margin: 0 0 18px 0;
          height: 230px;
          img {
               height: 230px;
          }
          .park-title-block {
               strong {
                    @include font-size(22);
               }
               i {
                    @include font-size(46);
                    top: 45%; 
               }
          }
     }
     .parks-row {
          &:nth-child(odd) {
               .park-large {
                    &:nth-child(even) {
                         margin-right: 0;
                    }
               }
          }
          &:nth-child(even) {
               .park-small {
                    &:nth-child(even) {
                         margin: 0 18px;
                    }
               }
          }
     }
    
}
.expanded-width-block {
     width: 100%;
     background-color: #f7f7f7;
     margin-top: 4.1875em;
     .featured-properties-block {
          h3 {
               text-align: center;
               font-weight: 600;
               margin: 2em 0 .125em;
               @include font-size(36);
          }
          p {
               text-align: center;
               font-weight: 300;
               margin-bottom: 2.625em;
          }
          ul {
               margin: 0;
               li {
                    list-style: none;
                    border-radius: 5px;
                    overflow: hidden;
                    margin:  0 10px;
                    .featured-property-block {
                         position: relative;                        
                         img {
                              width: auto;
                              height: 250px;
                              object-fit: cover;
                         }
                         .gradient-overlay {
                              position: absolute;
                              width: 100%;
                              top: 0;
                              bottom: 0;
                              background: -moz-linear-gradient(bottom, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                              background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                              background: linear-gradient(to top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                         }
                         .property-tag, .favorite-tag {
                              position: absolute;                            
                              z-index: 99999;
                         }
                         .property-tag {
                              top: .5em;
                              left: .5em;
                              padding:  .180em .65em;
                              border-radius: 3px;
                              color: #fff;
                              &.price {
                                   @include font-size(14);
                                   font-weight: 300;
                                   background-color: $property-tag;
                                   strong {
                                        @include font-size(18);
                                   }
                              }
                         }
                         .favorite-tag {
                              top: .25em;
                              right: .5em;
                              width: 34px;
                              height: 34px;
                              line-height: 34px;
                              text-align: center;
                              @include font-size(18);
                              color: #dfe0e1;
                              background-color: rgba(0,0,0,.5);
                              border-radius: 18px;
                              cursor: pointer;
                              &:hover {
                                   background-color: rgba(129, 30, 30,.9);
                              }
                              img {
                                   display: block;
                                   width: 20px;
                                   height: auto;
                                   margin: calc((34px - 16px ) / 2) calc((34px - 20px ) / 2);
                              }
                         }
                         .title-block {
                              width: 100%;
                              position: absolute;
                              z-index: 9999;
                              left: 0;
                              top: 58%;
                              padding:  .5em 1em;
                              &:hover {
                                   transition: .5s;
                                   height: 100%;
                              }
                              strong, span, i {
                                   color: #fff;
                              }
                              strong {
                                   font-weight: 600;
                                   display: block;
                                   @include font-size(18);
                                   line-height: 20px;
                              } 
                              i {
                                   position: absolute;
                                   right: .75em;
                                   top: .5em;
                                   @include font-size(32);
                              }
                         }
                         .options-block {
                              position: absolute;
                              bottom: 0;
                              left: 0;
                              width: 100%;
                              padding: .75em 1em;
                              background-color: rgba(0,0,0,.35);
                              & > ul {
                                   & > li {
                                        display: inline;
                                        color: #fff;
                                        padding: 0;
                                        margin-right: .125em;
                                        font-weight: 300;
                                        img {
                                             display: inline-block;
                                             width: 16px;
                                             height: 18px;
                                        }
                                        &.total-beds {
                                             img {
                                                  width: 29px;
                                                  height: 22px;
                                             }
                                        }
                                   }
                              }
                         }
                         .more-info {                              
                              position: absolute;
                              background: transparent;
                              top: 20%;
                              bottom: 0;
                              width: 100%;
                              z-index: 9999;
                              h4 {
                                   transition: all .5s ease-out;
                                   color: transparent;
                                   @include font-size(22);
                                   font-weight: 300;
                                   text-transform: uppercase;
                                   text-align: center;
                                   margin-top: 35%;
                              }
                              &:hover {
                                   transition: all .5s ease-out;
                                   top: 0;
                                   background: -moz-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                                   background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                                   background: linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                   h4 {                    
                                        color: #fff;
                                        margin-top: 30%;
                                   }
                              }
                         }
                    }
               }
          }
     }
     a.button {
          background-color: $second-color;
          width: 218px;
          color: #fff;
          padding: .5em 1em;
          @include font-size(20);
          border-radius: 5px;
          margin: 2em calc(50% - (218px / 2));
          &:hover {
               background-color: darken($second-color,10%);
          }
          &:last-child {
               margin-bottom: 4em;
          }
     }
}
.home-footer-content {
     text-align: center;
     padding: 0 150px;
     h1 {
          @include font-size(30);
          font-weight: 800;
          margin: 2em 0 1.25em;
     }
     p {
          &:last-child {
               margin-bottom: 4.375em;
          }
     }
}

@include breakpoint(small only) {
     .content-block {
          h3 {
               @include font-size(30);
               margin: 1.25em 0;
          }
          .park-large, .park-small {
               width: 100%;
               height: 160px;
               margin: 0 0 16px !important;
          }
     }
     .expanded-width-block {
          .featured-properties-block {
               h3 {
                    @include font-size(28);
                    margin-top: 1em;
               }
               p  {
                    margin-bottom: 1.312em;
               }                       
               ul {
                    li {
                         .featured-property-block {
                              .title-block {
                                   top: 50%;
                              }
                              .options-block {
                                   ul {
                                        li {
                                             @include font-size(15);
                                             i {
                                                  @include font-size(17);
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }    
          }
     }
     .home-footer-content {
          padding: 0 .5em;
          h1 {
               @include font-size(25);
               margin: 1.25em 0;
          }
     }
}

@include breakpoint(medium only) {
     .content-block {
          h3  {
               margin: 1.1em 0;
          }
          .parks-block {
               .park-large {
                    img {
                         height: 300px;
                         width: auto;
                    }
               }
               .park-small {
                    img {
                         height: 230px;
                         width: auto;
                    }
                    .park-title-block {
                         strong {
                              @include font-size(19);
                         }
                         i {
                              @include font-size(48);
                         }
                         .park-situation {
                              @include font-size(12);
                         }
                    }
               }
          }
     }
     .expanded-width-block {
          .featured-properties-block {             
               ul {
                    li {
                         .featured-property-block {
                              .title-block {
                                   top: 52%;
                              }
                              .options-block {
                                   ul {
                                        li {
                                             @include font-size(16);
                                             i {
                                                  @include font-size(18);
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }                   
          }
     }
     .home-footer-content {
          padding: 0 .75em;
     }
}