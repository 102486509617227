.content-banner-outer {
     position: relative;
     width: 100%;
     height: 480px;
     overflow: hidden;
     .content-banner {         
          width: inherit;
          img {
               width: 100%;
               height: 480px;
               object-fit: cover;
          }
     }
     .banner-gradient {
          bottom: 0;
          height: 480px;
          left: 0;
          width: 100%;
          z-index: 99;
          position: absolute;
          background: -moz-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
     }
}

.slick-arrow {
     position: absolute;
     top: calc(50% - (60px / 2));
     @include font-size(32);
     color: $font-color;
     background-color: $slick-arrows;
     width: 60px;
     height: 60px;
     line-height: 60px;
     text-align: center;
     border-radius: 32px;
     z-index: 999;
     &:hover, &:active, &:focus {
          cursor: pointer;
          color: darken($first-color, 10%);
     }
     &.fa-angle-right {
          right: -3em;
     }
     &.fa-angle-left {
          left: -3em;
     }
}

.detail-page-slider-outer {
     height: 585px;
     .detail-page-slider {  
          img {
               width: 100%;
               height: 585px;
               object-fit: cover;
               object-position: center center;
          }
         .slick-arrow {
              background-color: darken($slick-arrows,30%);
              color: #fff;
               &.fa-angle-right {
                    right: -3em;
               }
               &.fa-angle-left {
                    left: -3em;
               }
               &:hover, &:active {
                    background-color: darken($second-color,10%);
               }
          }
     }
}

// Fancybox styles
.fancybox-is-open {
     .fancybox-bg {
          opacity: .98;
     }
}

.fancybox-arrow:after {
     background-color: $first-color;
}

@include breakpoint(small only) {
     .content-banner-outer {
          .content-banner {
               img {
                    height: 480px;
                    width: auto;
                    object-fit: cover;
               }
          }
     }
     .detail-page-slider-outer {
          height: 460px;
          .detail-page-slider {
               img {
                    height: 360px;
               }
               .slick-arrow {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    top: calc(50% - (35px / 2));
                    &.fa-angle-left {
                         left: 0;
                    }
                    &.fa-angle-right {
                         right: 0;
                    }
               }
          }
     }
     .slick-arrow {
        display: none;
     }
}

@include breakpoint(medium only) {
     .content-banner-outer {
          .content-banner {
               img {
                    height: 480px;
                    width: auto;
                    object-fit: cover;
               }
          }
     }
     .detail-page-slider-outer {
          .detail-page-slider {
               .slick-arrow {
                    &.fa-angle-left {
                         left: 0;
                    }
                    &.fa-angle-right {
                         right: 0;
                    }
               }
          }
     }
}

@media screen and (min-width:48.0625em) and (max-width:80.625em) {
     .detail-page-slider-outer {
          .detail-page-slider {
               .slick-arrow {
                    &.fa-angle-left {
                         left: 0;
                    }
                    &.fa-angle-right {
                         right: 0;
                    }
               }
          }
     }
     .slick-arrow {
          background-color: rgba($slick-arrows, .75);
          &.fa-angle-right {
               right: -.4em;
          }
          &.fa-angle-left {
               left: -.4em;
               z-index: 9999;
          }
     }
}